.pick-list .indicator-item{
    
    display: flex;
    width:100%;
    margin-bottom:0px;
    align-items: center;
  }
  .pick-list .teamlogo{
    border-bottom-left-radius:50%;
    border-bottom-right-radius:50%;
    border-top-left-radius:50%;
    border-top-right-radius:50%;
  }
 .pick-list .indicator-item-icon{
    flex:1;
  }
   .pick-list .indicator-item-value{
    -webkit-box-flex: 0;
    flex: 0 1 75%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    align-items: flex-end;
  }
   .pick-list .indicator-value-counter{
    font-size: 1.84615rem;
  }
   .pick-list .indicator-value-title{
    font-weight: 300; text-align:right;
  }
  .schedule .list-group-item, .schedule .list-group{
      border:none !important;
      margin:none !important;
      padding:none !important;
  }
  .pick-list .strikeBox{
    background-color:#FCD7D4;
  }
  .list-group-item{
    border-color: #ccc;

  }