.ListGroupHeader{
    background-color: #007bff;
    color: white;
    padding-top:0px;
    padding-bottom:0px;
}

/*standings bar*/
.users .list-group-item{
    padding:3px 12px 3px 12px;
    font-size:14px;
}

.users .label{
    margin-top:1px;
}

.badge-strikes1{ background-color:#dbb2b2; }
.badge-strikes2{ background-color:#d48e8e; }
.badge-strikes3{ background-color:#e86d6d; }

.z-mini-btn{
    border-radius: 25px;
    color:#333;
    outline: none !important;
    margin-left:8px;
}
.refresh-btn{
    width: 35px;
    margin-right:0px;
}
.refresh-btn i{
    margin-left:-2px;
}

/*picks widget*/
.lock-picks-list .list-group-item{
    padding:0px;
}
.lock-picks-list .list-group-item-heading{
    background-color:#323232;
    color:#fff;
    font-size:18px;
    vertical-align: middle;
}

.lock-picks-list .list-group-item-heading-text{
    margin-left:60px;
}

.lock-picks-list .teamlogo{
    border-bottom-left-radius:50%;
    border-bottom-right-radius:50%;
    border-top-left-radius:50%;
    border-top-right-radius:50%;
    position: absolute;
  }


.z-lockedpicks-widget .pickTable{
    margin-bottom: 0px;
    display:flex;
    flex-wrap:wrap;
    display: flex;
    flex-direction:row;
    padding:0px 12px 6px 12px;
    margin-left:60px;
}

.z-lockedpicks-widget .isLoss{
    background-color: #fcd7d4;
}
.pickUserCount{
    font-size:10px;
}

.pickTable .user{
    flex-basis: 50%;
}

.z-lockedpicks-widget .list-group-item-heading{
    font-family: "Open Sans",sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    padding:6px;
}

.z-lockedpicks-widget .list-group-item-text{
    padding:12px;
}


@media (max-width: 350px){
/* font size on Iphone 5 and less */
    .lock-picks-list .list-group-item-heading{
        font-size:16px;
    }
    .users .list-group-item{
        font-size:12px;
    }
}

@media (max-width: 450px){
    .z-lockedpicks-widget .pickTable{
        margin-left:0px;
    }
    .list-group-item-heading-text{
        max-width: 250px;
    }
}
@media (max-width: 650px){
    /* hide "View Week" header on mobile */
    .weekSelector-title{
        display:none;
    }
    .weekSelector .btn, .weekSelector .btn:focus, .weekSelector .btn:active, .weekSelector Button.active{
        padding:0px !important;
        background:none;
        border:none;
    }
}
@media (min-width: 989px){
    .collapse-btn{ display:none;}
}
