.weekSelector{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:14px;
}
.weekSelector Button.active{
    color:#000;
    font-weight: bold;
}
.weekSelector .btn{
    color:#2cc185;
    font-weight: bold;
    border:none;
    padding:2px 6px 2px 6px;
}

