.help-preText{
    padding-bottom:18px;
}
.help-title{
    font-weight:bold;
}
.help-rule{
    font-size:.9em;
}
.schedule-list .indicator-item{
    
    display: flex;
    width:100%;
    margin-bottom:0px;
    align-items: center;
  }
  .schedule-list .teamlogo{
    border-bottom-left-radius:50%;
    border-bottom-right-radius:50%;
    border-top-left-radius:50%;
    border-top-right-radius:50%;
  }
  .schedule-list .indicator-item-icon{
    
  }
   .schedule-list .indicator-item-value{
    -webkit-box-flex: 0;
    flex: 0 1 90%;
    
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align:center;
  }
   .schedule-list .indicator-value-counter{
    font-size: 1.84615rem;
  }
   .schedule-list .indicator-value-title{
    font-weight: 300; 
    text-align:center;
  }
  .schedule .list-group-item, .schedule .list-group{
      border:none !important;
      margin:none !important;
      padding:none !important;
  }
  .schedule-list .strikeBox{
    background-color:#FCD7D4;
  }
  .list-group-item{
    border-color: #ccc;
  
  }