.App, body {
  margin: 0px;
  background-color:#f4f4f4;
  font-family: "Open Sans",sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;

}

body, html{ border:none;border-image-width:0;}

.container, .container-fluid{
	max-width:960px;
}

.App .navbar-brand {
  font-weight: bold;
  display: flex;
  align-items: center;
  height:62px;
}

.App .navbar-nav a{
	line-height: 2;
}

.App .flex{
	display:flex;
	justify-content: space-between
}
.App .flex .nav{
	margin-left:2px;
}

.navbar-brand>img {
  margin-right:7px;
  width:32px;
  height:32px;
}

.navbar-default .navbar-nav>li>a{
	color: #fff;
}

.navbar-default .navbar-link>a:hover, .navbar-default .navbar-link{
	color: #fff !important; text-decoration: none;}

.z-alert{
	margin-left: 40px;
	margin-right: 40px;
}

/* Nav Bar */

.z-header{
	margin: 0px;
	border:0px;
	border-radius: 0;
	background-color: #323232;
	color:#fff;
}

.z-header .navbar-default .navbar-nav>.active>a{
	background-color: transparent !important;
}

.z-header .icon{
	color: #fefefed7 !important;
	font-size: 18px;
}
.z-header .icon:hover{
	color: #fff !important;
}
.z-header-right .active>a, .z-header-right .active>a:focus, .z-header-right .active>a:hover{
	background-color:transparent !important;
}
.z-header-right a{
	color:white;
}
.z-header-right{
	padding-right:20px;
	flex-basis: auto;
	flex-direction:row;
	color:#fff;
}
.z-header-link, .z-header-link:hover{
	color:#FFF;
}

/* Sub Nav Bar */
.z-sub-header{
	border: 0;
    border-radius: 0;
    background-color: #2cc185;
}
.z-sub-header .icon{
	padding-right:8px;
	padding-top:4px;
	font-size: 20px !important;
}
.z-sub-header .container{
	padding-top:22px;
}
.z-sub-header .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus{
	background-color: #f4f4f4; color:#323232;
	border-top-left-radius:3px;
	border-top-right-radius:3px;
}
/* WIDGETS */
.z-widget{
 background-color: #fff;
 color: #000;
 margin-bottom:10px;
}

.z-widget-head{
 margin-bottom:19px;
 padding: 20px 20px 00px 20px;
}

.z-widget-head-compact{
	padding: 20px 20px 00px 20px;
}

.z-widget-body-text{
	font-size:13px;
	font-weight:300;
	margin-left:20px;
}

.z-widget-head .title, .z-widget-head-compact .title{
 font-weight:300;
 font-size:2rem;
 display:block;
}

.z-widget-body{
	font-weight:400;
	display:block;
	padding-left:10px;
	padding-right:10px;
	padding-bottom:1px;
}

.z-widget-loading{
	width:100%;
	text-align: center;
}

/* Navbar @ small devices */

.z-mobile-toggle{
	visibility: none;
	text-align: center;
	width:65%;
}
.navbar-toggle collapsed{
	border:none !important;
}
 .navbar-default .navbar-toggle:hover{
	background-color:transparent;
	border:none;
}
.navbar-default .navbar-toggle:hover{
	color:#fff !important;
}

@media (max-width: 767.98px){
	.z-header{
		background-color: #2cc185;
	}

	.z-sub-header{
		background-color: #323232;
		color:#fff
	}
	.z-sub-header .container{
		padding-top:0px;
	}
	.z-mobile-toggle{ visibility: visible;}
}

/* TEAM BUTTON STYLES: https://usteamcolors.com/nfl-colors/ */
.teamStyle_ARI{ color:white; background-color:#97233f; border-color: #000000;}
.teamStyle_ATL{ color:white; background-color:#a71930; border-color: #000000}
.teamStyle_BAL{ color:white; background-color:#241773; border-color: #9e7c0c;}
.teamStyle_BUF{ color:white; background-color:#c60c30; border-color: #00338d;}
.teamStyle_CAR{ color:white; background-color:#0085ca; border-color: #000000;}
.teamStyle_CHI{ color:white; background-color:#c83803; border-color: #0b162a;}
.teamStyle_CIN{ color:white; background-color:#fb4f14; border-color: #000;}
.teamStyle_CLE{ color:white; background-color:#ff3c00; border-color: #311d00}
.teamStyle_DAL{ color:white; background-color:#002244; border-color: #869397; }
.teamStyle_DEN{ color:white; background-color:#fb4f14; border-color:#002244; }
.teamStyle_DET{ color:white; background-color:#0076b6; border-color: #b0b7bc;}
.teamStyle_GB{ color:white; background-color:#203731; border-color: #ffb612;}
.teamStyle_HOU{ color:white; background-color:#a71930; border-color: #03202f;}
.teamStyle_IND{ color:white; background-color:#002c5f; border-color: #a5acaf;}
.teamStyle_JAC{ color:white; background-color:#006778; border-color: #9f792c;}
.teamStyle_KC{ color:white; background-color:#e31837; border-color: #ffb612;}
.teamStyle_LAC{ color:white; background-color:#0073cf; border-color: #ffb612}
.teamStyle_LAR{ color:white; background-color:#b3995d; border-color: #002244}
.teamStyle_MIA{ color:white; background-color:#008e97; border-color: #f26a24;}
.teamStyle_MIN{ color:white; background-color:#4f2683; border-color: #ffc62f;}
.teamStyle_NE{ color:white; background-color:#002244; border-color: #c60c30;}
.teamStyle_NO{ color:white; background-color:#d3bc8d; border-color: #000;}
.teamStyle_NYG{ color:white; background-color:#0b2265; border-color: #a71930;}
.teamStyle_NYJ{ color:white; background-color:#003f2d; border-color:#000;}
.teamStyle_LV{ color:white; background-color:#000; border-color: #a5acaf;}
.teamStyle_PHI{ color:white; background-color:#004c54; border-color: #a5acaf;}
.teamStyle_PIT{ color:white; background-color:#ffb612; border-color: #000000; }
.teamStyle_SEA{ color:white; background-color:#69be28; border-color: #002244; }
.teamStyle_SF{ color:white; background-color:#b3995d; border-color: #aa0000; }
.teamStyle_TB{ color:white; background-color:#d50a0a; border-color: #ff7900; }
.teamStyle_TEN{ color:white; background-color:#4b92db; border-color: #c60c30; }
.teamStyle_WSH{ color:white; background-color:#773141; border-color:#ffb612 }

/* NFL Team Branding -- global setup */
/* Generated by http://css.spritegen.com CSS Sprite Generator */

.TeamLogo_ari, .TeamLogo_atl, .TeamLogo_bal, .TeamLogo_buf, .TeamLogo_car,
.TeamLogo_chi, .TeamLogo_cin, .TeamLogo_cle, .TeamLogo_dal, .TeamLogo_den,
.TeamLogo_det, .TeamLogo_gb, .TeamLogo_hou, .TeamLogo_ind, .TeamLogo_jac,
.TeamLogo_kc, .TeamLogo_lac, .TeamLogo_lar, .TeamLogo_mia, .TeamLogo_min,
.TeamLogo_ne, .TeamLogo_no, .TeamLogo_nyg, .TeamLogo_nyj, .TeamLogo_lv,
.TeamLogo_phi, .TeamLogo_pit, .TeamLogo_sea, .TeamLogo_sf, .TeamLogo_tb,
.TeamLogo_ten, .TeamLogo_wsh
{ display: inline-block; background: url('/images/teamlogo_sprite.png') no-repeat;
	overflow: hidden; text-indent: -9999px; text-align: left; }

.TeamLogo_ari { background-position: -1px -0px; width: 170px; height: 170px; }
.TeamLogo_atl { background-position: -172px -0px; width: 170px; height: 170px; }
.TeamLogo_bal { background-position: -343px -0px; width: 170px; height: 170px; }
.TeamLogo_buf { background-position: -514px -0px; width: 170px; height: 170px; }
.TeamLogo_car { background-position: -685px -0px; width: 170px; height: 170px; }
.TeamLogo_chi { background-position: -1px -171px; width: 170px; height: 170px; }
.TeamLogo_cin { background-position: -172px -171px; width: 170px; height: 170px; }
.TeamLogo_cle { background-position: -343px -171px; width: 170px; height: 170px; }
.TeamLogo_dal { background-position: -514px -171px; width: 170px; height: 170px; }
.TeamLogo_den { background-position: -685px -171px; width: 170px; height: 170px; }
.TeamLogo_det { background-position: -1px -342px; width: 170px; height: 170px; }
.TeamLogo_gb { background-position: -172px -342px; width: 170px; height: 170px; }
.TeamLogo_hou { background-position: -343px -342px; width: 170px; height: 170px; }
.TeamLogo_ind { background-position: -514px -342px; width: 170px; height: 170px; }
.TeamLogo_jac { background-position: -685px -342px; width: 170px; height: 170px; }
.TeamLogo_kc { background-position: -1px -513px; width: 170px; height: 170px; }
.TeamLogo_lac { background-position: -172px -513px; width: 170px; height: 170px; }
.TeamLogo_lar { background-position: -343px -513px; width: 170px; height: 170px; }
.TeamLogo_mia { background-position: -514px -513px; width: 170px; height: 170px; }
.TeamLogo_min { background-position: -685px -513px; width: 170px; height: 170px; }
.TeamLogo_ne { background-position: -1px -684px; width: 170px; height: 170px; }
.TeamLogo_no { background-position: -172px -684px; width: 170px; height: 170px; }
.TeamLogo_nyg { background-position: -343px -684px; width: 170px; height: 170px; }
.TeamLogo_nyj { background-position: -514px -684px; width: 170px; height: 170px; }
.TeamLogo_lv { background-position: -685px -684px; width: 170px; height: 170px; }
.TeamLogo_phi { background-position: -1px -855px; width: 170px; height: 170px; }
.TeamLogo_pit { background-position: -172px -855px; width: 170px; height: 170px; }
.TeamLogo_sea { background-position: -343px -855px; width: 170px; height: 170px; }
.TeamLogo_sf { background-position: -514px -855px; width: 170px; height: 170px; }
.TeamLogo_tb { background-position: -685px -855px; width: 170px; height: 170px; }
.TeamLogo_ten { background-position: -1px -1026px; width: 170px; height: 170px; }
.TeamLogo_was { background-position: -172px -1026px; width: 170px; height: 170px; }

/* Generated by http://css.spritegen.com CSS Sprite Generator */

.TeamLogo_sm_ari, .TeamLogo_sm_atl, .TeamLogo_sm_bal, .TeamLogo_sm_buf, .TeamLogo_sm_car,
.TeamLogo_sm_chi, .TeamLogo_sm_cin, .TeamLogo_sm_cle, .TeamLogo_sm_dal, .TeamLogo_sm_den,
.TeamLogo_sm_det, .TeamLogo_sm_gb, .TeamLogo_sm_hou, .TeamLogo_sm_ind, .TeamLogo_sm_jac,
.TeamLogo_sm_kc, .TeamLogo_sm_lac, .TeamLogo_sm_lar, .TeamLogo_sm_mia, .TeamLogo_sm_min,
.TeamLogo_sm_ne, .TeamLogo_sm_no, .TeamLogo_sm_nyg, .TeamLogo_sm_nyj, .TeamLogo_sm_lv,
.TeamLogo_sm_phi, .TeamLogo_sm_pit, .TeamLogo_sm_sea, .TeamLogo_sm_sf, .TeamLogo_sm_tb,
.TeamLogo_sm_ten, .TeamLogo_sm_was
{ display: inline-block; background: url('/images/teamlogo_sm_sprite.png') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }

.TeamLogo_sm_ari { background-position: -1px -0px; width: 100px; height: 100px; }
.TeamLogo_sm_atl { background-position: -102px -0px; width: 100px; height: 100px; }
.TeamLogo_sm_bal { background-position: -203px -0px; width: 100px; height: 100px; }
.TeamLogo_sm_buf { background-position: -304px -0px; width: 100px; height: 100px; }
.TeamLogo_sm_car { background-position: -405px -0px; width: 100px; height: 100px; }
.TeamLogo_sm_chi { background-position: -1px -101px; width: 100px; height: 100px; }
.TeamLogo_sm_cin { background-position: -102px -101px; width: 100px; height: 100px; }
.TeamLogo_sm_cle { background-position: -203px -101px; width: 100px; height: 100px; }
.TeamLogo_sm_dal { background-position: -304px -101px; width: 100px; height: 100px; }
.TeamLogo_sm_den { background-position: -405px -101px; width: 100px; height: 100px; }
.TeamLogo_sm_det { background-position: -1px -202px; width: 100px; height: 100px; }
.TeamLogo_sm_gb { background-position: -102px -202px; width: 100px; height: 100px; }
.TeamLogo_sm_hou { background-position: -203px -202px; width: 100px; height: 100px; }
.TeamLogo_sm_ind { background-position: -304px -202px; width: 100px; height: 100px; }
.TeamLogo_sm_jac { background-position: -405px -202px; width: 100px; height: 100px; }
.TeamLogo_sm_kc { background-position: -1px -303px; width: 100px; height: 100px; }
.TeamLogo_sm_lac { background-position: -102px -303px; width: 100px; height: 100px; }
.TeamLogo_sm_lar { background-position: -203px -303px; width: 100px; height: 100px; }
.TeamLogo_sm_mia { background-position: -304px -303px; width: 100px; height: 100px; }
.TeamLogo_sm_min { background-position: -405px -303px; width: 100px; height: 100px; }
.TeamLogo_sm_ne { background-position: -1px -404px; width: 100px; height: 100px; }
.TeamLogo_sm_no { background-position: -102px -404px; width: 100px; height: 100px; }
.TeamLogo_sm_nyg { background-position: -203px -404px; width: 100px; height: 100px; }
.TeamLogo_sm_nyj { background-position: -304px -404px; width: 100px; height: 100px; }
.TeamLogo_sm_lv { background-position: -405px -404px; width: 100px; height: 100px; }
.TeamLogo_sm_phi { background-position: -1px -505px; width: 100px; height: 100px; }
.TeamLogo_sm_pit { background-position: -102px -505px; width: 100px; height: 100px; }
.TeamLogo_sm_sea { background-position: -203px -505px; width: 100px; height: 100px; }
.TeamLogo_sm_sf { background-position: -304px -505px; width: 100px; height: 100px; }
.TeamLogo_sm_tb { background-position: -405px -505px; width: 100px; height: 100px; }
.TeamLogo_sm_ten { background-position: -1px -606px; width: 100px; height: 100px; }
.TeamLogo_sm_was { background-position: -102px -606px; width: 100px; height: 100px; }

/* Generated by http://css.spritegen.com CSS Sprite Generator */

.TeamLogo_xs_ari, .TeamLogo_xs_atl, .TeamLogo_xs_bal, .TeamLogo_xs_buf, .TeamLogo_xs_car,
.TeamLogo_xs_chi, .TeamLogo_xs_cin, .TeamLogo_xs_cle, .TeamLogo_xs_dal, .TeamLogo_xs_den,
.TeamLogo_xs_det, .TeamLogo_xs_gb, .TeamLogo_xs_hou, .TeamLogo_xs_ind, .TeamLogo_xs_jac,
.TeamLogo_xs_kc, .TeamLogo_xs_lac, .TeamLogo_xs_lar, .TeamLogo_xs_mia, .TeamLogo_xs_min,
.TeamLogo_xs_ne, .TeamLogo_xs_no, .TeamLogo_xs_nyg, .TeamLogo_xs_nyj, .TeamLogo_xs_lv,
.TeamLogo_xs_phi, .TeamLogo_xs_pit, .TeamLogo_xs_sea, .TeamLogo_xs_sf, .TeamLogo_xs_tb,
.TeamLogo_xs_ten, .TeamLogo_xs_was
{ display: inline-block; background: url('/images/teamlogo_xs_sprite.png') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }

.TeamLogo_xs_ari { background-position: -1px -0px; width: 50px; height: 50px; }
.TeamLogo_xs_atl { background-position: -52px -0px; width: 50px; height: 50px; }
.TeamLogo_xs_bal { background-position: -103px -0px; width: 50px; height: 50px; }
.TeamLogo_xs_buf { background-position: -154px -0px; width: 50px; height: 50px; }
.TeamLogo_xs_car { background-position: -205px -0px; width: 50px; height: 50px; }
.TeamLogo_xs_chi { background-position: -1px -51px; width: 50px; height: 50px; }
.TeamLogo_xs_cin { background-position: -52px -51px; width: 50px; height: 50px; }
.TeamLogo_xs_cle { background-position: -103px -51px; width: 50px; height: 50px; }
.TeamLogo_xs_dal { background-position: -154px -51px; width: 50px; height: 50px; }
.TeamLogo_xs_den { background-position: -205px -51px; width: 50px; height: 50px; }
.TeamLogo_xs_det { background-position: -1px -102px; width: 50px; height: 50px; }
.TeamLogo_xs_gb { background-position: -52px -102px; width: 50px; height: 50px; }
.TeamLogo_xs_hou { background-position: -103px -102px; width: 50px; height: 50px; }
.TeamLogo_xs_ind { background-position: -154px -102px; width: 50px; height: 50px; }
.TeamLogo_xs_jac { background-position: -205px -102px; width: 50px; height: 50px; }
.TeamLogo_xs_kc { background-position: -1px -153px; width: 50px; height: 50px; }
.TeamLogo_xs_lac { background-position: -52px -153px; width: 50px; height: 50px; }
.TeamLogo_xs_lar { background-position: -103px -153px; width: 50px; height: 50px; }
.TeamLogo_xs_mia { background-position: -154px -153px; width: 50px; height: 50px; }
.TeamLogo_xs_min { background-position: -205px -153px; width: 50px; height: 50px; }
.TeamLogo_xs_ne { background-position: -1px -204px; width: 50px; height: 50px; }
.TeamLogo_xs_no { background-position: -52px -204px; width: 50px; height: 50px; }
.TeamLogo_xs_nyg { background-position: -103px -204px; width: 50px; height: 50px; }
.TeamLogo_xs_nyj { background-position: -154px -204px; width: 50px; height: 50px; }
.TeamLogo_xs_lv { background-position: -205px -204px; width: 50px; height: 50px; }
.TeamLogo_xs_phi { background-position: -1px -255px; width: 50px; height: 50px; }
.TeamLogo_xs_pit { background-position: -52px -255px; width: 50px; height: 50px; }
.TeamLogo_xs_sea { background-position: -103px -255px; width: 50px; height: 50px; }
.TeamLogo_xs_sf { background-position: -154px -255px; width: 50px; height: 50px; }
.TeamLogo_xs_tb { background-position: -205px -255px; width: 50px; height: 50px; }
.TeamLogo_xs_ten { background-position: -1px -306px; width: 50px; height: 50px; }
.TeamLogo_xs_was { background-position: -52px -306px; width: 50px; height: 50px; }

/* Generated by http://css.spritegen.com CSS Sprite Generator */

.team_svg_ari, .team_svg_atl, .team_svg_bal, .team_svg_buf, .team_svg_car,
.team_svg_chi, .team_svg_cin, .team_svg_cle, .team_svg_dal, .team_svg_den,
.team_svg_det, .team_svg_gb, .team_svg_hou, .team_svg_ind, .team_svg_jac,
.team_svg_kc, .team_svg_lac, .team_svg_lar, .team_svg_mia, .team_svg_min,
.team_svg_ne, .team_svg_no, .team_svg_nyg, .team_svg_nyj, .team_svg_lv,
.team_svg_phi, .team_svg_pit, .team_svg_sea, .team_svg_sf, .team_svg_tb,
.team_svg_ten, .team_svg_wsh
{ display: inline-block; background: url('/images/team_logos_svg.png') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }

.team_svg_ari { background-position: -1px -0px; width: 50px; height: 50px; }
.team_svg_atl { background-position: -52px -0px; width: 50px; height: 50px; }
.team_svg_bal { background-position: -103px -0px; width: 50px; height: 50px; }
.team_svg_buf { background-position: -154px -0px; width: 50px; height: 50px; }
.team_svg_car { background-position: -205px -0px; width: 50px; height: 50px; }
.team_svg_chi { background-position: -1px -51px; width: 50px; height: 50px; }
.team_svg_cin { background-position: -52px -51px; width: 50px; height: 50px; }
.team_svg_cle { background-position: -103px -51px; width: 50px; height: 50px; }
.team_svg_dal { background-position: -154px -51px; width: 50px; height: 50px; }
.team_svg_den { background-position: -205px -51px; width: 50px; height: 50px; }
.team_svg_det { background-position: -1px -102px; width: 50px; height: 50px; }
.team_svg_gb { background-position: -52px -102px; width: 50px; height: 50px; }
.team_svg_hou { background-position: -103px -102px; width: 50px; height: 50px; }
.team_svg_ind { background-position: -154px -102px; width: 50px; height: 50px; }
.team_svg_jac { background-position: -205px -102px; width: 50px; height: 50px; }
.team_svg_kc { background-position: -1px -153px; width: 50px; height: 50px; }
.team_svg_lac { background-position: -52px -153px; width: 50px; height: 50px; }
.team_svg_lar { background-position: -103px -153px; width: 50px; height: 50px; }
.team_svg_mia { background-position: -154px -153px; width: 50px; height: 50px; }
.team_svg_min { background-position: -205px -153px; width: 50px; height: 50px; }
.team_svg_ne { background-position: -1px -204px; width: 50px; height: 50px; }
.team_svg_no { background-position: -52px -204px; width: 50px; height: 50px; }
.team_svg_nyg { background-position: -103px -204px; width: 50px; height: 50px; }
.team_svg_nyj { background-position: -154px -204px; width: 50px; height: 50px; }
.team_svg_lv { background-position: -205px -204px; width: 50px; height: 50px; }
.team_svg_phi { background-position: -1px -255px; width: 50px; height: 50px; }
.team_svg_pit { background-position: -52px -255px; width: 50px; height: 50px; }
.team_svg_sea { background-position: -103px -255px; width: 50px; height: 50px; }
.team_svg_sf { background-position: -154px -255px; width: 50px; height: 50px; }
.team_svg_tb { background-position: -205px -255px; width: 50px; height: 50px; }
.team_svg_ten { background-position: -1px -306px; width: 50px; height: 50px; }
.team_svg_wsh { background-position: -52px -306px; width: 50px; height: 50px; }