.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }
  
  .Home .notes h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .Home .notes p {
    color: #666;
  }
  .Home .lander div {
    padding-top: 20px;
  }
  .Home .lander div a:first-child {
    margin-right: 20px;
  }

  .userStats{
    background-color: #fff;
  }
  .userStats .indicator-item, .pick-list .indicator-item{
    
    display: flex;
    width:100%;
    margin-bottom:0px;
    align-items: center;
  }
  .userStats .indicator-item{
    border-bottom: 1px solid #e6e6e6;
    padding:12px 30px;
  }
  .pick-list .teamlogo{
    border-bottom-left-radius:50%;
    border-bottom-right-radius:50%;
    border-top-left-radius:50%;
    border-top-right-radius:50%;
  }
  .userStats .indicator-item-icon, .pick-list .indicator-item-icon{
    flex:1;
  }
  .userStats .icon{
    color: #2cc185;
    font-size: 3.07692rem;
  }
  .userStats .indicator-item-value, .pick-list .indicator-item-value{
    -webkit-box-flex: 0;
    flex: 0 1 75%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    align-items: flex-end;
  }
  .userStats .indicator-value-counter, .pick-list .indicator-value-counter{
    font-size: 1.84615rem;
  }
  .userStats .indicator-value-title, .pick-list .indicator-value-title{
    font-weight: 300; text-align:right;
  }