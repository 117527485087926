.card-description{
    margin: 3px !important;
    
}
.card-description p{
    font-size: 10px !important;
}
.card-sub-title {
    font-size:12px !important;
}
.card-title a {
    font-size:15px !important;
}
.selectForm{
    z-index: 5000 !important;
}