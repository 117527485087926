@media all and (min-width: 480px) {
    .ChangePassword {
      padding: 60px 0;
    }
  
    .ChangePassword form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  