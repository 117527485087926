.team-button{ 
    display:flex; -ms-flex-align: stretch;
    justify-content: space-between;
    border-radius: 0;
}
.team-button div.teamLogo{
    border-bottom-left-radius:25%;
    border-bottom-right-radius:25%;
    border-top-left-radius:25%;
    border-top-right-radius:25%;
    margin-left:6px;
    
  }
.team-button div.teamName{
    padding-left:12px;
    padding-right:12px;
    flex-basis:80%;
}
.homeBanner{ 
    background-image: url('/images/homebanner.png');
    background-repeat: no-repeat;
    background-position: top right ;
}
.team-button{
    padding:6px;
    font-size:16px;
    flex:50%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width:3px;
    border-bottom-style: solid;
}
.team-button:disabled{
    text-decoration: line-through;
}

.team-button:hover{
    background-color:#007bff;
    color: white;
}