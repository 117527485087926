.gameTime{
    font-size:10px; 
    font-style: italic;
    margin-top:4px;
}


.ListGroupHeader{
    background-color: #007bff;
    color: white;
    padding-top:0px;
    padding-bottom:0px;
}
.vsrow{
    padding-top:12px;
}
.pickContainer{
    padding:0px !important;
}
.pickContainer button:disabled,.pickContainer button[disabled]{
    background-color: #999; 
    font-style: italic;
}

.pickContainer button:disabled:hover{
    background-color:#333;
    color:white;
}
.pick-row{
    margin-bottom:10px;
}


.vslabel{ font-size:15px;}
.gameTime{ font-size:10px;}
.teamrow{
    padding:0px;
    margin:0px;
    display:flex;
    justify-content: space-evenly;
}
.teamrow button{
    margin:0px;
}
.teamLogo{
    border-bottom-left-radius:50%;
    border-bottom-right-radius:50%;
    border-top-left-radius:50%;
    border-top-right-radius:50%;
    
}


/* style for the user picks list */
  .pick-list .teamlogo{
    border-bottom-left-radius:50%;
    border-bottom-right-radius:50%;
    border-top-left-radius:50%;
    border-top-right-radius:50%;
  }
   .pick-list .indicator-item-icon{
    flex:1;
  }
  .pick-list .indicator-item-value{
    -webkit-box-flex: 0;
    flex: 0 1 75%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    align-items: flex-end;
    text-align: center;
  }
  .pick-list .indicator-value-counter{
    font-size: 1.84615rem;
  }
  .pick-list .indicator-value-title{
    font-weight: 300; text-align:right;
  }
  .pick-list .indicator-item-delete{
      margin-left:10px; display: inline;
  }
  .pick-list .indicator-value-subtitle{
      font-size:12px;
  }


/* small screens */
@media (max-width: 560px) {
    .team-button div.teamName{
        flex-basis:70% !important;
    }
    .team-button{
        min-width:105px;
    }
    .PickList li{
        font-size:14px;
    }
   
}

@media(max-width: 420px){
    .team-button div.teamName{
        flex-basis:40% !important;
        margin-top:6px;
        font-size:13px;
        max-width:90px;
    }
    .team-button{
        min-width:140px;
    }
}