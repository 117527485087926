.dialog-nostyle {
    margin:0px;
    border:none;
    padding:0px;
  }

.dialog-parent-nostyle{
  padding:0px !important;
}

.modal-title{
  font-size:18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}